/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import './Reviews.css';
import google from './social.png';
import nike from './nike.png';
import netflix from './netflix.png';
import dell from './dell.png';
import close from './invisible.png';
import { motion } from "framer-motion";
function Reviews() {

  const [logo, setLogo] = useState(null);
  const [company, setCompany] = useState("");
  const [reviewremark, setReviewremark] = useState("");
  const [remarkerrole, setRemarkerrole] = useState("");


  // if(modal) {
  //   document.body.classList.add('active-modal')
  // } else {
  //   document.body.classList.remove('active-modal')
  // }


  const CUSTOMERS = [
    {
      Cname: 'Google',
      imgURL: google,
    },
    {
      Cname: 'Nike',
      imgURL: nike,
    },
    {
      Cname: 'Netflix',
      imgURL: netflix,
    },
    {
      Cname: 'Dell',
      imgURL: dell
    },
  ]

  const reviews = [
    {
      name: 'Joshep White',
      imgURL: google,
      company:"Google",
      role : 'Senior Developer At Google',
      remarks : "Working with KARBON has been an absolute game-changer for our business. Their innovative solutions and expertise have transformed the way we operate, making us more efficient and competitive in the market. The team's dedication to understanding our unique needs and delivering tailored solutions is truly commendable. KARBON's commitment to excellence and exceptional customer service sets them apart as a trusted partner. We look forward to a continued partnership that drives our success"
    },
    {
      name: 'Shazia Khan',
      imgURL: dell,
      company:"Dell",
      role : 'Marketing Manager at Dell',
      remarks : "I can't say enough good things about KARBON. From the moment we engaged with their team, we knew we were in capable hands. Their depth of knowledge in the IT industry is astounding, and their ability to translate complex technical concepts into actionable strategies is truly impressive. KARBON's solutions have significantly improved our IT infrastructure, enhancing our security and performance. Their professionalism and responsiveness make them a pleasure to work with. Choosing KARBON was one of the best decisions we've made for our company"
    },
    {
      name: 'Mark Jhon',
      imgURL: nike,
      company:"Nike",
      role : 'Product designer at Nike',
      remarks : "KARBON has exceeded our expectations in every way. Their approach to problem-solving is refreshingly innovative, and they consistently deliver results that align with our business goals. Their team's deep expertise spans a wide range of IT services, ensuring that we have a one-stop solution for all our technology needs. What sets KARBON apart is their genuine partnership mentality. They're not just service providers; they're strategic advisors who are invested in our success. We're proud to have KARBON as our IT partner and look forward to many more years of collaboration"
    },

  ]

  return (
    <div className='Reviews'id='reviews' >

<motion.div  
     initial={{
      opacity: 0,
      translateX: 50 ,
      translateY: -50,
    }}
    animate={{ opacity: 1, translateX: 0, translateY: 0 }}
    transition={{ duration: 0.3, delay:0.1 }}>
<h2 className='head2'>TRUSTED BY THE CUSTOMERS</h2>
</motion.div>
     

      <div className='iconDiv'>
        {CUSTOMERS.map((element,i) => (
          <motion.img className='imgicon' src={element.imgURL}
          initial={{
            opacity: 0,
            translateX: -50,
            translateY: -50,
          }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{ duration: 0.3, delay: i * 0.2 }} />
        ))}
      </div>
 

          <div className='Remarks'>
          {reviews.map((element,i) => (
            <motion.div className='remark' data-bs-toggle="modal" data-bs-target="#exampleModal"
            
            initial={{
              opacity: 0,
              // translateX: i % 2 === 0 ? -50 : 50,
              translateY: 50,
            }}
            animate={{ opacity: 1, translateX: 0, translateY: 0 }}
            transition={{ duration: 0.3, delay: i * 0.2 }}
            onClick={()=>{
              setLogo(element.imgURL)
              setCompany(element.company)
              setReviewremark(element.remarks)
              setRemarkerrole(element.name + " " + element.role)
            }}>
          <img className='clogo' src={element.imgURL} />
          <div className='remarktext'>
            <p className='reviewremark' >" {element.remarks.slice(0, 100)} ... "</p>
            <label className='remarkerrole'>~ {element.name} {element.role}</label>
          </div>


          </motion.div>
        ))}




          </div>

         
          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   
 
   <div  className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
     <div className="modal-content" id="modal">
       <div className="modal-header">
         <h1 className="modal-title fs-5" id="ModalLabel">{company}</h1>
         <img id="close" src={close}   data-bs-dismiss="modal" aria-label="Close"/>
       </div>
       <div className="modal-body">
       <img className='clogo' src={logo} />
          <div className='modalremark'>
            <p className='modalreviewremark' >" {reviewremark} "</p>
            <label className='modalremarkerrole'>From {remarkerrole}</label>
          </div>
          
       </div>
       
     </div>
   </div>
 </div>
   

    </div>
  )
}

export default Reviews