
import './App.css';

import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import AboutUs from './components/about/AboutUS';
import Services from './components/services/Services';
import Projects from './components/projects/Projects';
import FooterB from './components/footerB/FooterB';
import Reviews from './components/reviews/Reviews';
import Footer from './components/footerM/Footer';
import Foot from './components/foot/Foot';


function App() {

  document.body.style.backgroundColor = 'black';
  document.body.style.width = '100%';
  document.body.style.overflowX = 'hidden'
  return (
  
    
      <Router >
          <Navbar />
      <div>
        <Routes>
      
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/services" element={<Services/>} />
        <Route exact path="/projects" element={<Projects/>} />  
        <Route exact path="/customers" element={<Reviews/>} />
        <Route exact path="/contact" element={<FooterB/>} />

         

        </Routes>
      </div>
      <Foot />   
    </Router>
 

  );
}

export default App;
