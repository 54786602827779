/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './foot.css';
import hexa from './hexa3.ico';
import { BsGithub } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
function Foot() {
    return (
        <div className='foot'>
            <div className='logoDiv'>
                <img
                    className='logosvg'
                    src={hexa} />
                <label className='karbon'>KarbonIon</label> </div>
            <div className='endline'>
                <p>Copyright 2024</p>
            </div>
            <div className='icon'>
                <a href='https://github.com/karbon-6' target='_blank' rel='noopener noreferrer'>
                    <BsGithub className='github' />
                </a>
                <a href='https://www.linkedin.com/in/karbon-ion-5210142ab/?originalSubdomain=pk' target='_blank' rel='noopener noreferrer'>
                    <FaLinkedin className='linkdn' />
                </a>
                <a href='mailto:karbon.ion6@gmail.com'>
                    <SiGmail className='gmail' />
                </a>
            </div>
        </div>
    )
}

export default Foot
