/* eslint-disable react/style-prop-object */
import React from 'react'
import './home.css';
import logoKarbon from './logoKarbon.png';
import globe from './globe5.png';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import Foot from '../foot/Foot';
function Home() {
  return (
    <>
    <div className='maindiv' id='/'>
      <div className='div1'
      >
      

        <h1 className='Qoute' >Reliable people for your difficult problems</h1>
        {/* <h4 className='Mission'>The Mission of Our Company is To provide Peoplewith best ever Tech Solutions with <br/>  cutting edjge Technologioes and our Creativity</h4> */}

      </div>
      <div className='div2'>
        {/* <img className='logo' src={logoKarbon} alt="Description of the image" /> */}
        <h4 className='Mission'>At our core, we're driven by a mission: to transform industries and uplift businesses by harnessing the potential of advanced software solutions. By combining our expertise, creativity, and a relentless pursuit of excellence, we aim to empower our clients to achieve new heights of efficiency, growth, and success. Together, let's shape the digital future and make possibilities limitless.</h4>
        {/* <button className='aboutbtn' style={{"--clr:#1e9bff">About Karbon</button> */}
        {/* <Link to='/contact' className='aboutbtn' style={{ '--clr': '#1e9bff' }}><span className='spanc'>Contact Us</span></Link> */}

      </div>
      <div className='globediv'>
        <div className='text'>
          <h2 className='globehead'>Elevate, Expand, Excel: Unleashing IT Potential on a Global Scale</h2>
          <label className='desc'>Across the world, we make connections easy. Our customized solutions break down borders, helping businesses succeed everywhere in today's connected world.</label>
        </div>
        <img className='globeimg' src={globe} />
        {/* <img className='globeimg' src={globe2} /> */}

      </div>
    </div>
    {/* <Foot/> */}
    </>
  )
}

export default Home
