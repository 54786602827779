/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './about.css';
import { BsGithub } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import person from './person.png';
const Founder = {
  name: 'Atif Chatha ',
  imageUrl: person,
  imageSize: 150,
  role: "Founder",
  discription: " Professor at The University of Lahore."
};

const COF = [
  {
    name: 'Muaz Asim',
    imageUrl: person,
    imageSize: 150,
    role: "Front-End | BacKend Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''

  },
  {
    name: 'Bilal Munir',
    imageUrl: person,
    imageSize: 150,
    role: "Front-End | BacKend Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''
  },
  {
    name: 'Hasham Asad',
    imageUrl: person,
    imageSize: 150,
    role: "Manager | Backend Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''
  },
  {
    name: 'Mustafa Raja',
    imageUrl: person,
    imageSize: 150,
    role: "Team Lead | BacKend Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''
  },
  {
    name: 'Raheeb Rehman',
    imageUrl: person,
    imageSize: 150,
    role: "System Architect| BacKend Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''
  },
  {
    name: 'Saher Arif',
    imageUrl: person,
    imageSize: 150,
    role: "Designer | Front-End Developer ",
    info: "Bachelor's in Computer Science Form The University Of Lahore,",
    github: "",
    linkdn: ""
    , gmail: ''
  }
]




function AboutUS() {

  return (
    <div className='aboutus' id='about'>


      <div className='mainDiv'>

        <div className='karbonDiv'>
          <h1 className='WIK'>WHAT IS KARBON? </h1>
          <h2 className='WIKD'>Karbon is a multinational company that fulfils your dreams and your requirements <br /> so buckle up and waiot for the response!</h2>
        </div>

        <div className='founderDiv'>
          <img
            className='FounderImg'
            src={Founder.imageUrl}
          />
          <h1 className='FounderName'> {Founder.name} | Founder </h1>
          <label className='FounderDiscription'>{Founder.discription}</label>
        </div>



      </div>
      <h1 className='heading'>Co-Founders</h1>
      <div className='Co-Founders'>

        {COF.map((element) => (
          <div className='cof'>
            <div className='sector'>
              <img className='cimg' src={element.imageUrl} />
              <div className='label'>
                <label className='cfR'>{element.role}</label>
                <p className='cfD'>{element.info}</p>
                <div>
                  <FaLinkedin className='icons' />
                  <BsGithub className='icons' />
                  <SiGmail className='icons' />
                </div>
              </div>
            </div>

            <h2 className='cofname'>{element.name}</h2>

          </div>
        ))}
      </div>

    </div>


  )
}

export default AboutUS