/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import './navbar.css';
import { NavLink } from 'react-router-dom';

import hexa from './hexa3.ico';
function Navbar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
  useEffect(() => {
    const buttondiv = document.querySelector('.buttondiv');
    const btns = document.querySelector(".navbuttons");
    // btns.addEventListener('click', (e) => {
    //   const b = e.target;
    //   btns.style.color = '#9c9c9c';
    //   b.style.color = '#ffffff';
    // })


    buttondiv.addEventListener('click', (e) => {
   
      // let btn = '';
      // if (window.scrollY <= 20) {
      //   btn = document.querySelector('#h')
      // }
      // else {
      //   btn = e.target.closest('.navbuttons ');
      // }
      const btn = e.target.closest('.navbuttons ');

      if (!btn) return;
      let w=0;
      buttondiv.style.setProperty('--marging',w+ '%');
      const calcleft = btn.offsetLeft;
      buttondiv.style.setProperty('--left', calcleft + 'px');
      let calcwidth = btn.offsetWidth / buttondiv.offsetWidth;
      // calcwidth -= 3;
      buttondiv.style.setProperty('--width', calcwidth);
    })
    console.log('Component has mounted!');
  }, []);


  return (
    <>


      <div className='nav'>
        <div className='logoDiv2'>
          <img
            className='logosvg2'
            src={hexa} />
          <label className='karbon'>KarbonIon</label> </div>


        <nav className='buttondiv'>
          <NavLink activeClass="active" id='h' to="/"  className='navbuttons' >Home</NavLink>
          <NavLink activeClass="active" to="/services" className='navbuttons' >Services</NavLink>
          <NavLink activeClass="active" to="/projects" className='navbuttons' >Project</NavLink>
          {/* <NavLink activeClass="active" to="/customers" className='navbuttons' >Our Customers</NavLink> */}
          <NavLink activeClass="active" to="/contact" className='navbuttons' >Contact-Us</NavLink>
        </nav>

        <div className="dropdown">
          <a id='menu' className="btn  dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Menu
          </a>

          <ul className="dropdown-menu">
            <li> <NavLink id='h' to="/"  className='navbuttonss' >Home</NavLink></li>
            <li><NavLink  to="/services" className='navbuttonss' >Services</NavLink></li>
            <li> <NavLink  to="/projects" className='navbuttonss' >Projects</NavLink></li>
            {/* <li><NavLink to="/customers" className='navbuttonss' >Customers</NavLink></li> */}
            <li><NavLink to="/contact" className='navbuttonss' >Contact Us</NavLink></li>
          </ul>
        </div>





      </div>
    </>
  )
}

export default Navbar
