import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './footerB.css';
import logoKarbon from './logoKarbon.png';
import { BsGithub } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import sendmail from './sendmail.png'
import send from './send1.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function FooterB() {

  const form = useRef();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // email = emailField.value.trim();
    const name = nameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const message = messageRef.current.value.trim();
    if (name && email && message && emailPattern.test(email)) {


      nameRef.current.value = '';
      emailRef.current.value = '';
      messageRef.current.value = '';
      emailjs.sendForm('service_qfctzp9', 'template_yamf6gr', form.current, 'kvJE3gIq6hU0sbo5X')
        .then((result) => {
          console.log(result.text);
          toast.success('Email Sent Successfully', {
            position: "top-right",
            autoClose: 1010,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }, (error) => {
          toast.error(error.text, {
            position: "top-right",
            autoClose: 3010,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(error.text);
        });



    }

    else {

      toast.error("Please Provide All Credentials", {
        position: "top-right",
        autoClose: 3010,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

  };


  return (
    <div className='footerb' id="contact">
      {/* <div className='blobdiv'>
      <section>
          <div className='blob'>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="blue" transform="translate(100 100)" >
    <animate attributeName='d' dur={"10000ms"}
    repeatCount={"indefinite"}
    values="M41.4,-21.4C51.1,-7,54.7,13.3,46.8,25.8C38.9,38.3,19.4,42.9,-0.1,43C-19.7,43,-39.4,38.6,-47.7,25.9C-56,13.1,-52.9,-7.8,-43,-22.3C-33.2,-36.7,-16.6,-44.7,-0.4,-44.4C15.8,-44.2,31.6,-35.8,41.4,-21.4Z;

    M58.5,-33.8C68.2,-17.1,63,8.1,50.7,31.2C38.5,54.3,19.3,75.3,0.9,74.7C-17.4,74.2,-34.9,52.2,-47.6,28.8C-60.3,5.5,-68.2,-19.3,-59.5,-35.5C-50.8,-51.7,-25.4,-59.3,-0.5,-59C24.5,-58.8,48.9,-50.6,58.5,-33.8Z;

    M59.7,-34.1C69.6,-17.4,64.4,8.4,52,22.6C39.5,36.8,19.7,39.5,0.7,39.1C-18.4,38.7,-36.8,35.3,-47.6,22.1C-58.3,8.8,-61.4,-14.2,-52.2,-30.5C-43,-46.9,-21.5,-56.6,1.7,-57.6C24.9,-58.5,49.9,-50.9,59.7,-34.1Z;

    M41.4,-21.4C51.1,-7,54.7,13.3,46.8,25.8C38.9,38.3,19.4,42.9,-0.1,43C-19.7,43,-39.4,38.6,-47.7,25.9C-56,13.1,-52.9,-7.8,-43,-22.3C-33.2,-36.7,-16.6,-44.7,-0.4,-44.4C15.8,-44.2,31.6,-35.8,41.4,-21.4Z">
    
    </animate>
    </path>
    </svg>
    </div>
    {/* glow effect */}
      {/* <div className='blob'>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="#A7F0BA" transform="translate(100 100)" >
    <animate attributeName='d' dur={"10000ms"}
    repeatCount={"indefinite"}
    values="M41.4,-21.4C51.1,-7,54.7,13.3,46.8,25.8C38.9,38.3,19.4,42.9,-0.1,43C-19.7,43,-39.4,38.6,-47.7,25.9C-56,13.1,-52.9,-7.8,-43,-22.3C-33.2,-36.7,-16.6,-44.7,-0.4,-44.4C15.8,-44.2,31.6,-35.8,41.4,-21.4Z;

    M58.5,-33.8C68.2,-17.1,63,8.1,50.7,31.2C38.5,54.3,19.3,75.3,0.9,74.7C-17.4,74.2,-34.9,52.2,-47.6,28.8C-60.3,5.5,-68.2,-19.3,-59.5,-35.5C-50.8,-51.7,-25.4,-59.3,-0.5,-59C24.5,-58.8,48.9,-50.6,58.5,-33.8Z;

    M59.7,-34.1C69.6,-17.4,64.4,8.4,52,22.6C39.5,36.8,19.7,39.5,0.7,39.1C-18.4,38.7,-36.8,35.3,-47.6,22.1C-58.3,8.8,-61.4,-14.2,-52.2,-30.5C-43,-46.9,-21.5,-56.6,1.7,-57.6C24.9,-58.5,49.9,-50.9,59.7,-34.1Z;

    M41.4,-21.4C51.1,-7,54.7,13.3,46.8,25.8C38.9,38.3,19.4,42.9,-0.1,43C-19.7,43,-39.4,38.6,-47.7,25.9C-56,13.1,-52.9,-7.8,-43,-22.3C-33.2,-36.7,-16.6,-44.7,-0.4,-44.4C15.8,-44.2,31.6,-35.8,41.4,-21.4Z">
    
    </animate>
    </path>
    </svg>
    {/* </div>  
        </section>
      </div>  */}
      {/* <div className='D'>   */}
      {/* <div className='contact'>
        <h1 className='Rheading'>Reach Us</h1>
        <h3 className='messagelabel'>Want To Ask Something?</h3>
        <input className='message' placeholder='leave your Message here' />
        {/* <br /> */}
      {/*<label className='note'>You won't regret working with us; just give us a You won't regret working with us; just give us a chance to prove oursel
          You won't regret working with us; just give us a chance to prove oursel
          You won't regret working with us; just give us a chance to prove ourselchance to prove ourselves to you.</label>
        <div className='icons'>
          <BsGithub className='github' />
          <FaLinkedin className='linkdn' />
          <SiGmail className='gmail' />
        </div>
        </div> 

      </div>  */}

      {/* <div className='card'>
        <h1 className='Cardtitle'>REACH US</h1>
        <p className='subtitle'>whatever you want to ask, whatever you want to order and whatever you want us to build!</p>
      </div>  */}
      <h2 className='head2'>Connect With Us</h2>

      <form className='card' ref={form} onSubmit={sendEmail}>

        <img src={sendmail} className='cardimg' />
        {/* <h1 className='ctitle'>REACH US!</h1> */}
        <p className='subtitle'>Got a question? Just give us a ping! Our team is always on the lookout, ready to connect and provide!</p>
        <label className='Lable1'>Your name :</label>
        <input ref={nameRef} className='input' type='text' placeholder='Name' name='user_name'></input>
        <label className='Lable1'>Your mail :</label>
        <input ref={emailRef} className='input' type='email' placeholder='Email Address' name='user_email'></input>
        <label className='Lable1'>Your message :</label>
        <textarea ref={messageRef} className='input2' type='text' placeholder='Message' name='message'></textarea>
        <button type='submit' className='btnsub'>SEND <img className='sendicon' src={send} />

        </button>

      </form>
      <ToastContainer />
      <div className='blobA'>

      </div>
    </div>
  )
}

export default FooterB
