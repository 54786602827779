import React from 'react'
import './projects.css';
// import rating from './ratings.png';
import { useRef } from "react";
import { motion } from "framer-motion";

function Projects() {

  
  const projects = [
    {
      Ptitle: 'BidMagnet',
      //   imageUrl: mobile,
      Discription: "An Ecommerce Web Application Build using Django DataBase SQLite and Fronted with Djinja"
    },
    {
      Ptitle: 'MailConnect',
      //   imageUrl: mobile,
      Discription: "A mailing Application or build using Django Backend and Frontend with JavaScript."
    },
    {
      Ptitle: 'CineXquisite',
      //   imageUrl: mobile,
      Discription: "An app that gives user access to movies from any timeline to any language, with a range of cinemas that are nearby you to get tickets of the latest shows and much more."
    },
    {
      Ptitle: 'ScholarBridge',
      //   imageUrl: mobile,
      Discription: "An online school portal seamlessly connects students and educators through virtual classrooms, resource sharing, and real-time collaboration, enhancing the educational experience for all."
    },
    {
      Ptitle: 'Encyclopedia',
      //   imageUrl: mobile,
      Discription: "A gateway to a universe of simple and accessible knowledge, covering everything from A to Z. where users show there creativity by a there own articles and insights to enrich the community."
    },
    



  ]


  return (
    <div className='main' id='projects'>
   

   <motion.div  
     initial={{
      opacity: 0,
      translateX: 50 ,
      translateY: -50,
    }}
    animate={{ opacity: 1, translateX: 0, translateY: 0 }}
    transition={{ duration: 0.3, delay:0.1 }}>
<h2 className='head2'>WHAT HAVE WE ALREADY DONE?</h2>
</motion.div>
  

      <div className='Projects'>
        {projects.map((element,i) => (
          <motion.div className='project'
          initial={{
            opacity: 0,
            translateX: 50,
            translateY: -50,
          }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{ duration: 0.3, delay: i * 0.2 }}
          >
            {/* <img className='img' src={element.imageUrl}/> */}
            <h3 className='title'>{element.Ptitle}</h3>
            <div className='hovdis'>
              <label className='discriptionP' >{element.Discription}</label>
            </div>
          </motion.div>
        ))}
      </div>

    </div>
  )
}

export default Projects
