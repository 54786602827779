/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './services.css';
import module from './electronics.png';
import web from './website-codes.png';
import mobile from './application.png';
import react from './react.png';
import reactNative from './reactN.png';
import nodejs from './nodejs.png';
import Django from './django.png';
import express from './express2.png';
import spring from './spring2.png';

import globe2 from './globe2.png';
import Projects from '../projects/Projects';

import { motion } from "framer-motion";

function Services() {

  
  const services = [
    {
      Service: 'Mobile App Development',
      imageUrl: mobile,
      Discription: "Imagine your ideas as vibrant apps, brought to life by our mobile app development magic. We create user-friendly wonders that transform your vision into reality."
    },
    {
      Service: 'Website Development',
      imageUrl: web,
      Discription: "Deliver advanced web applications constructed using frameworks such as Django, SpringBoot, and React, enhancing user experiences for your audience."
    },
    {
      Service: 'Module Development',
      imageUrl: module,
      Discription: "From diverse modules to comprehensive IT solutions, we're here to bring your ideas to life through seamless implementation."
    }

  ]

  const Frameworks = [
    {
      Name: 'Django',
      imageUrl: Django,
    },
    {
      Name: 'react',
      imageUrl: react,
    },
    {
      Name: 'springboot',
      imageUrl: spring,
    },
    {
      Name: 'react native',
      imageUrl: reactNative,
    },
    // {
    //   Name: 'node js',
    //   imageUrl: nodejs,
    // },
    {
      Name: 'express js',
      imageUrl: express,
    }

  ]




  return (
    <div className='main2' id='services'>
  
    
<motion.div  
     initial={{
      opacity: 0,
      translateX: 50 ,
      translateY: -50,
    }}
    animate={{ opacity: 1, translateX: 0, translateY: 0 }}
    transition={{ duration: 0.3, delay:0.1 }}>
<h2 className='head2'>WHAT DO WE PROVIDE?</h2>
</motion.div>
      


      <div className='Services'>

        {services.map((element ,i) => (
          <motion.div className='service'
          initial={{
            opacity: 0,
            translateX: i % 2 === 0 ? -50 : 50,
            translateY: -50,
          }}
          animate={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{ duration: 0.3, delay: i * 0.2 }}
          >
            <img className='img' src={element.imageUrl} />
            <h3 className='title'>{element.Service}</h3>
            <label className='discription' >{element.Discription}</label>
          </motion.div>
        ))}
      </div>
      <h2 className='head21'>FRAMEWORKS WE WORK IN </h2>
      <div className='frameworks'>
        {Frameworks.map((element , i) => (
          <div className='framework'
         
          >
            <img className='fwi' src={element.imageUrl} />
            {/* <h3 className='title'>{element.Name}</h3> */}
          </div>
        ))}
      </div>
      {/* <Projects /> */}
     
    </div>
  )
}

export default Services
